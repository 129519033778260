import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createTextVNode as _createTextVNode, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/Icons/exclamation-red.svg'
import _imports_1 from '@/assets/img/Icons/exclamation-grey.svg'


const _withScopeId = n => (_pushScopeId("data-v-61fbe74c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "message-container" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "footer" }
const _hoisted_5 = { class: "buttons" }
const _hoisted_6 = ["title"]
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "scrolling-container" }
const _hoisted_9 = { class: "note-container" }
const _hoisted_10 = { class: "top-actions" }
const _hoisted_11 = {
  key: 0,
  src: _imports_0,
  width: "8",
  height: "20",
  alt: "Mark Urgent"
}
const _hoisted_12 = {
  key: 1,
  src: _imports_1,
  width: "8",
  height: "20",
  alt: "Unmark Urgent"
}
const _hoisted_13 = { class: "actions" }
const _hoisted_14 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_ChatMessages = _resolveComponent("ChatMessages")!
  const _component_ScreenBottomButton = _resolveComponent("ScreenBottomButton")!
  const _component_ChatInput = _resolveComponent("ChatInput")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_SimpleSpinner = _resolveComponent("SimpleSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.composable.loading.value)
        ? (_openBlock(), _createBlock(_component_Loading, {
            key: 0,
            loading: _ctx.composable.loading.value
          }, null, 8, ["loading"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_ctx.convQuestions)
              ? (_openBlock(), _createBlock(_component_ChatMessages, _mergeProps({ key: 0 }, _ctx.$attrs, {
                  ref: "chatMessages",
                  "has-network-connection": _ctx.hasNetworkConnection,
                  class: "msgs",
                  messages: _ctx.chatMessages,
                  "custom-data": _ctx.chatCustomData,
                  "chat-status": _ctx.chatStatus,
                  avatar: _ctx.composable.avatar.value,
                  name: _ctx.composable.abbrName.value,
                  "is-user-basic": 
                        _ctx.userPermissions.includes('ROLE_USER_BASICS')
                    ,
                  "conv-questions": _ctx.convQuestions,
                  "no-pad": "",
                  onUndoChat: _ctx.onUndoChat
                }), null, 16, ["has-network-connection", "messages", "custom-data", "chat-status", "avatar", "name", "is-user-basic", "conv-questions", "onUndoChat"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_4, [
              (!_ctx.editingNote && _ctx.animated && _ctx.hasNetworkConnection)
                ? (_openBlock(), _createBlock(_component_ScreenBottomButton, { key: 0 }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_5, [
                        (
                                    _ctx.userPermissions.includes('ROLE_ADD_NOTES')
                                )
                          ? (_openBlock(), _createElementBlock("button", {
                              key: 0,
                              type: "button",
                              class: "btn primary-btn",
                              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.editNote && _ctx.editNote(...args)))
                            }, " Internal Note "))
                          : _createCommentVNode("", true),
                        (_ctx.canReply)
                          ? (_openBlock(), _createElementBlock("button", {
                              key: 1,
                              type: "button",
                              class: _normalizeClass(["btn primary-btn", {
                                    disabled: _ctx.composable.dontContact.value,
                                }]),
                              title: _ctx.replyTooltip,
                              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goReply && _ctx.goReply(...args)))
                            }, " Email Reply ", 10, _hoisted_6))
                          : _createCommentVNode("", true)
                      ])
                    ], undefined, true),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_Transition, { name: "fade" }, {
                default: _withCtx(() => [
                  (_ctx.editingNote)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("div", null, [
                              (_ctx.chatTags)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    (_ctx.userHasMlp)
                                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                          _createElementVNode("div", _hoisted_10, [
                                            (
                                                            _ctx.mentionedUsers.length >
                                                            0
                                                        )
                                              ? (_openBlock(), _createElementBlock("div", {
                                                  key: 0,
                                                  class: "mark-urgent-action",
                                                  onClick: _cache[2] || (_cache[2] = ($event: any) => (
                                                            _ctx.markAsUrgent =
                                                                !_ctx.markAsUrgent
                                                        ))
                                                }, [
                                                  (_ctx.markAsUrgent)
                                                    ? (_openBlock(), _createElementBlock("img", _hoisted_11))
                                                    : (_openBlock(), _createElementBlock("img", _hoisted_12))
                                                ]))
                                              : _createCommentVNode("", true)
                                          ]),
                                          _createVNode(_component_ChatInput, {
                                            content: _ctx.note,
                                            mentions: _ctx.mentionUsers,
                                            tags: _ctx.chatTags.tags,
                                            placeholder: "Write an Internal Note (You can add a hashtag here with #. You can also mention your teammate with @ and their name, and tap the exclamation mark to mark it as urgent.",
                                            "on-update": _ctx.onChatInput,
                                            "custom-class": "mobile-note",
                                            onSave: _ctx.saveNote,
                                            onMentionAdded: 
                                                        _ctx.onMentionAdded
                                                    ,
                                            onMentionDeleted: 
                                                        _ctx.onMentionDeleted
                                                    
                                          }, null, 8, ["content", "mentions", "tags", "on-update", "onSave", "onMentionAdded", "onMentionDeleted"])
                                        ], 64))
                                      : (_openBlock(), _createBlock(_component_ChatInput, {
                                          key: 1,
                                          content: _ctx.note,
                                          mentions: _ctx.mentionUsers,
                                          tags: _ctx.chatTags.tags,
                                          placeholder: "Write an Internal Note (You can add a hashtag here with # or mention your teammate with @ and their name)",
                                          "on-update": _ctx.onChatInput,
                                          "custom-class": "mobile-note",
                                          onSave: _ctx.saveNote
                                        }, null, 8, ["content", "mentions", "tags", "on-update", "onSave"]))
                                  ], 64))
                                : _createCommentVNode("", true)
                            ]),
                            _createElementVNode("div", _hoisted_13, [
                              (_ctx.userHasMlp)
                                ? (_openBlock(), _createBlock(_component_BaseButton, {
                                    key: 0,
                                    class: "add-btn",
                                    disabled: !_ctx.addEnabled,
                                    loading: _ctx.savingNote,
                                    onClick: _ctx.saveNote
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(" Add ")
                                    ], undefined, true),
                                    _: 1
                                  }, 8, ["disabled", "loading", "onClick"]))
                                : (_openBlock(), _createElementBlock("button", {
                                    key: 1,
                                    type: "button",
                                    class: _normalizeClass(["send-btn btn secondary-btn", { disabled: !_ctx.addEnabled }]),
                                    disabled: !_ctx.addEnabled,
                                    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.saveNote && _ctx.saveNote(...args)))
                                  }, [
                                    _createTextVNode(" Add "),
                                    (_ctx.savingNote)
                                      ? (_openBlock(), _createBlock(_component_SimpleSpinner, {
                                          key: 0,
                                          size: "small",
                                          class: "spinner",
                                          "line-bg-color": "#2E5BFF",
                                          "line-fg-color": "#fff"
                                        }))
                                      : _createCommentVNode("", true)
                                  ], 10, _hoisted_14))
                            ])
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ], undefined, true),
                _: 1
              })
            ])
          ]))
    ])
  ]))
}