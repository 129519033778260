import { createApp, h } from 'vue'
import App from './App.vue'
import router from './router'
import PortalVue from 'portal-vue'
import { useRoute } from 'vue-router'
import mitt from 'mitt'
import store from './store'
import './main.less'
import * as Quill from 'quill'
import 'quill/dist/quill.core.css'
import PrimeVue from 'primevue/config'
import PrimeVuePreset from '@/entities/primevue/primevuePreset'
import ToastService from 'primevue/toastservice'
import '/node_modules/primeicons/primeicons.css'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import { isLocal } from '@/mobile/src/utils/misc'
import AnCommon from '@/plugins/an-common'
import { Event } from '@bugsnag/core'
import * as amplitude from '@amplitude/analytics-browser'
import { initializeAmplitude } from '@/mobile/src/services/amplitude'

const {
    bugsnagMobileApiKey,
    bugsnagTestStage,
    bugsnagProductionStage,
} = require('@/mobile/src/utils/bugsnag.ts')

// need to import Quill globally as the web is using external Quill.js
// to make IE work and we are sharing the same component ChatInput.vue
declare global {
    interface Window {
        Quill: any
        pendo: any
        cypressTest: any
        app: any
        ANAPI_TOKEN: string
        UIkit: any
        bugsnagClient: any
        google: any
        amplitude: any
    }
}

window.Quill = Quill

// Init Amplitude
initializeAmplitude()

const route = useRoute()

const app = createApp({
    name: 'AppV4',
    route,
    render: () => h(App),
})
app.use(PrimeVue, PrimeVuePreset)
app.use(ToastService)
app.use(router)
app.use(store)
app.use(AnCommon, { store, ismobile: true })
app.config.globalProperties.emitter = mitt()

app.use(PortalVue)

const onError = (event: Event) => {
    //Testing for our custom keys; this can be expanded.
    if (
        event.originalError &&
        event.originalError.hasOwnProperty('askNicelyMetadata')
    ) {
        event.addMetadata('askNicelyMetadata', {
            askNicelyMetadata: event.originalError.askNicelyMetadata,
        })
    }
}

Bugsnag.start({
    apiKey: bugsnagMobileApiKey,
    appVersion: process.env.JS_VERSION,
    releaseStage:
        process.env.APP_ENV === 'local'
            ? bugsnagTestStage
            : process.env.NODE_ENV,
    plugins: [new BugsnagPluginVue()],
    enabledReleaseStages: [bugsnagTestStage, bugsnagProductionStage],
    onError,
})

const bsPlugin = Bugsnag.getPlugin('vue')
if (bsPlugin) {
    app.use(bsPlugin)
}

if (isLocal) {
    window.app = app
}

app.mount('#app')
