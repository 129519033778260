import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45cb3bfa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "things-to-work-on" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopicRowContent = _resolveComponent("TopicRowContent")!
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.badgesToDisplay, (badge) => {
      return (_openBlock(), _createElementBlock(_Fragment, null, [
        (badge.count > 0)
          ? (_openBlock(), _createBlock(_component_RouterLink, {
              key: badge.badgeId,
              to: {
                    name: _ctx.routeName,
                    params: { value: badge.badgeId, teamMemberUserId: _ctx.teamMemberUserId },
                },
              class: "topic-row topic-link"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_TopicRowContent, {
                  label: badge.label || badge.value,
                  "total-responses": badge.count,
                  "unseen-responses": badge.countUnseen
                }, null, 8, ["label", "total-responses", "unseen-responses"]),
                _createVNode(_component_FontAwesomeIcon, {
                  class: "right-chevron-icon",
                  icon: _ctx.faChevronRight
                }, null, 8, ["icon"])
              ], undefined, true),
              _: 2
            }, 1032, ["to"]))
          : (_openBlock(), _createElementBlock("div", {
              key: badge.badgeId,
              class: "topic-row"
            }, [
              _createVNode(_component_TopicRowContent, {
                label: badge.label || badge.value,
                "total-responses": badge.count,
                "unseen-responses": badge.countUnseen
              }, null, 8, ["label", "total-responses", "unseen-responses"]),
              _createVNode(_component_FontAwesomeIcon, {
                class: "right-chevron-icon",
                icon: _ctx.faChevronRight
              }, null, 8, ["icon"])
            ]))
      ], 64))
    }), 256))
  ]))
}