<template>
    <div
        v-if="filterActive && (!tsMobileView || rules.length > 0)"
        :class="[
            'filter-bar-active',
            tsMobileView
                ? 'filter-bar-active-mobile'
                : 'filter-bar-active-desktop',
        ]"
    >
        <div class="filter-bar-active-container">
            <transition-group name="rules">
                <div
                    v-for="(rule, i) in rules"
                    v-show="!tsMobileView || rules.length - 1 == i"
                    :key="rule.column + '/' + rule.operator"
                    :class="[
                        'filter-rule',
                        { 'filter-rule-multiple': rules.length > 1 },
                    ]"
                    @click="
                        removeFilterRule({
                            id: filterActive.id,
                            column: rule.column,
                            operator: rule.operator,
                        })
                    "
                >
                    {{ formatColumn(rule.column)
                    }}<span
                        v-if="
                            shouldDisplayColon(rule.column) && rule.value.length
                        "
                        >:</span>
                    {{ formatLabel(rule.column, rule.value, rule.operator) }}
                    <span class="del">&times;</span>
                </div>
            </transition-group>
            <Dropdown ref="dropdown" :modal="tsMobileView">
                <template #trigger>
                    <div
                        v-if="tsMobileView && rules.length > 1"
                        class="more-filters-note"
                    >
                        + {{ rules.length - 1 }} more filter<template
                            v-if="rules.length > 2"
                            >s</template>...
                    </div>
                </template>

                <template #content>
                    <div
                        class="options"
                        :class="{ 'ts-modal-c': tsMobileView }"
                    >
                        <FilterModalMobileHeader
                            v-if="tsMobileView"
                            title="Edit Filter"
                            @close="closeModal"
                        />
                        <div
                            class="current-filters"
                            :class="{
                                'mobile-view-modal-content-margin':
                                    tsMobileView,
                            }"
                        >
                            <div
                                v-for="rule in rules"
                                :key="rule.column + '/' + rule.operator"
                            >
                                <div class="current-filter-title">
                                    {{ formatColumn(rule.column) }}
                                </div>
                                <div
                                    v-for="value in rule.value"
                                    :key="
                                        rule.column +
                                        '/' +
                                        rule.operator +
                                        '/' +
                                        value
                                    "
                                    class="current-filter-value"
                                >
                                    <div>{{ formatColumn(value) }}</div>
                                    <div class="icon-trash">
                                        <img
                                            src="@/components/WebView/icons/Trashcan.svg"
                                            @click="
                                                removeFilterValue({
                                                    ruleValue: value,
                                                    id: filterActive.id,
                                                    column: rule.column,
                                                })
                                            "
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="current-filter-clear">
                                <div></div>
                                <div
                                    @click="
                                        removeAllFilters({
                                            id: filterActive.id,
                                        })
                                    "
                                >
                                    CLEAR ALL
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </Dropdown>

            <div
                v-if="rules && rules.length > 0 && !tsMobileView"
                class="filter-save"
                @click="initSaveFilter"
            >
                <div>save filter</div>
                <img src="@/assets/img/Icons/Grey/Plus.svg" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { Action, Getter, namespace, State } from 'vuex-facing-decorator'
import { IFilterOption, IFilter, IFilterRule } from '../entities/filter'
import Dropdown from './Dropdown.vue'
import FilterModalMobileHeader from '@/components/FilterModalMobileHeader.vue'
import { IScorecardSetting } from '@/pages/appstore/components/Scorecard/scorecard-settings-entity'
const ScorecardSettingsModule = namespace('scorecardSettings')

@Component({
    components: { FilterModalMobileHeader, Dropdown },
})
export default class FilterBarActive extends Vue {
    @Getter public filterActive?: IFilter
    @Getter public filterRuleOptions?: {
        general: IFilterOption[]
        custom: IFilterOption[]
    }

    @Action public removeAllFilters
    @Action public removeFilterValue
    @Action public removeFilterRule
    @Action public setFilterEditing
    @Getter public tsMobileView
    @Getter public scorecardTopics

    @ScorecardSettingsModule.Getter
    readonly scorecardList!: IScorecardSetting[]

    @ScorecardSettingsModule.Action
    loadScorecardList

    public operatorSymbols: object = {
        gt: '> ',
        lt: '< ',
        in: '',
        notin: '≠ ',
    }

    public shouldDisplayColon(column): boolean {
        return ![
            'has_no_scorecard',
            'all_scorecards',
            'all_responses',
        ].includes(column)
    }
    public get rules() {
        const filterActive = this.filterActive
        return filterActive && filterActive.filter_rules
            ? filterActive.filter_rules.filter((rule) =>
                  this.filterFilterRulesForDisplay(rule)
              )
            : []
    }

    /**
     * If a filter only has the 'any' rule by itself, or it's the question_type rule, don't show it.
     */
    public filterFilterRulesForDisplay(rule: IFilterRule): boolean {
        const hasAnyOnly =
            rule.value.length === 1 && rule.value.find((v) => v === 'any')
        const isQuestionTypeRule = rule.column === 'question_type'

        return !(hasAnyOnly || isQuestionTypeRule)
    }

    public async mounted() {
        if (!this.scorecardList.length) {
            await this.loadScorecardList()
        }
    }

    public formatLabel(
        scorecardField: string,
        value: string[],
        operator: string
    ) {
        return (
            this.operatorSymbols[operator] +
            value
                .map((v) => v.replace(/_c$/g, '').replace(/_/g, ' '))
                .map(
                    (v) => v.replace(/^\d+:\s*/, '') // This line removes the number before the colon
                )
                .map((v) =>
                    this.scorecardList.find(
                        (scorecardTopic) =>
                            scorecardTopic.field === scorecardField
                    ) &&
                    this.scorecardTopics.find(
                        (scorecardTopic) => scorecardTopic.id === parseInt(v)
                    )
                        ? this.scorecardTopics.find(
                              (scorecardTopic) =>
                                  scorecardTopic.id === parseInt(v)
                          ).topic_name
                        : v
                )
                .filter((v) => {
                    if (value.length > 1) {
                        return v !== 'any'
                    }
                    return true
                })
                .join(', ')
        )
    }

    public formatColumn(column: string) {
        const options = this.filterRuleOptions
        const option =
            options &&
            options.general
                .concat(options.custom)
                .find((f: IFilterOption) => f.value === column)
        return ((option && option.label) || column)
            .replace(/_c$/g, '')
            .replace(/_/g, ' ')
    }

    public initSaveFilter() {
        const active = this.filterActive
        if (active) {
            this.setFilterEditing({ id: active.id, mode: 'create' })
        }
    }

    private closeModal() {
        (this.$refs.dropdown as any).$data.opened = false
    }
}
</script>

<style scoped lang="less">
@import '../styles/palette';

.filter-bar-active {
    flex: 1;
    padding-right: 10px;
    display: inline-block;
}

@media (max-width: 1280px) {
    .filter-bar-active-desktop {
        width: calc(100% - 38px - 44px);
    }

    .filter-bar-active-mobile {
        padding-right: 0;
        width: 100%;
    }
}

.filter-bar-active-container {
    background: @backgroundColor;
    border-radius: 20px;
    padding: 3px 0px;
    &:after {
        content: '';
        display: table;
        clear: both;
    }
}
.filter-rule {
    background: @ctaColor;
    color: @white;
    border-radius: 20px;
    padding: 6px 24px 6px 16px;
    margin: 3px 0px 3px 6px;
    font-weight: bold;
    cursor: pointer;
    max-width: 300px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    float: left;
}
.filter-save {
    color: @ctaColor;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 11px;
    padding: 10px 10px 5px 10px;
    width: 120px;
    cursor: pointer;
    float: left;
    display: flex;
    img {
        filter: @blueFilter;
        width: 20px;
        margin: -3px 0px 0px -3px;
    }
}
.rules-enter-active,
.rules-leave-active {
    transition: all 0.5s;
}
.rules-enter-from,
.rules-leave-to {
    opacity: 0;
    max-width: 0px;
}
.del {
    opacity: 0.7;
    position: absolute;
    top: 5px;
    right: 10px;
}
.more-filters-note {
    font-size: 10px;
    color: @grey40;
    padding: 0 6px;
    text-transform: none;
    margin-top: 4px;
    float: left;
}
.options {
    background: @white;
}
.current-filter-title {
    background: @greyLight;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    padding: 8px 16px;
    color: @grey40;
}
.current-filter-value {
    display: flex;
    justify-content: space-between;
    padding: 10px 16px;
    border-bottom: 1px solid rgba(46, 91, 255, 0.08);
    align-items: center;

    .icon-trash {
        display: flex;
        align-items: center;
    }
}
.current-filter-clear {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    padding: 8px 16px;
    color: @grey40;
}
.current-filter-clear div:nth-child(2) {
    color: @blue;
}
@media (min-width: 1281px) {
    div {
        display: inline-block;
    }
}
@media (max-width: 1280px) {
    .filter-rule {
        font-size: 11px;
        padding: 2px 24px 2px 12px;
        max-width: calc(100% - (97px));
    }

    .filter-rule-multiple {
        max-width: calc(100% - (177px));
    }

    .del {
        top: 2px;
        right: 8px;
    }

    .filter-save {
        padding: 0;
        width: auto;
        float: right;
        margin-top: 6px;

        img {
            position: relative;
            top: -1px;
        }
    }

    .filter-bar-active-container {
        .dropdown {
            top: 2px;
        }
    }
}
</style>
