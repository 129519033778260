<template>
    <div
        v-if="!/\/questions\/archived/.test(route.fullPath) || hasArchived"
        :class="[
            'responses-container',
            { 'uk-width-small-1-1 uk-width-large-3-10': isSideBar },
        ]"
        data-test-tag="dashboard-responses"
    >
        <div class="title">
            <span class="text">
                <slot name="responseLabel">
                    {{ responseLabel }}:
                    <span class="filter">{{
                        (unescapedTitle || 'NPS').toUpperCase() === 'NPS' &&
                        isFiveScore
                            ? 'Rating'
                            : unescapedTitle || 'NPS'
                    }}</span>
                    <div class="router-link-container" @click="clearFilter">
                        <span v-if="currentPath === '/personalview'">
                            <img
                                v-if="title !== '' && title !== 'NPS'"
                                :src="getClearFilterImage()"
                            />
                        </span>
                        <span v-else>
                            <RouterLink
                                v-if="title !== '' && title !== 'NPS'"
                                tag="img"
                                :to="clearFilterLink"
                                class="clear-filter"
                                replace
                            >
                                <img
                                    :src="getClearFilterImage()"
                                    alt="clear filter"
                                />
                            </RouterLink>
                        </span>
                    </div>
                </slot>
            </span>
            <span class="showing">
                {{
                    responsesMap[key]
                        ? formatNumber(responsesMap[key].length)
                        : 0
                }}
                {{
                    responsesMap[key] && responsesMap[key].length
                        ? ' of ' + formatNumber(responsesMap[key][0].total)
                        : ''
                }}
            </span>
        </div>
        <div class="body">
            <ScorecardBadgeHeading
                v-if="showBadges && hasSelectedPositiveBadge"
                :key="scorecard.selectedBadge.value"
            ></ScorecardBadgeHeading>
            <div ref="responses" class="responses">
                <Intersectional
                    v-if="tsMobileView"
                    :on-lower-scroll-end="handleScrollEnd"
                >
                    <DashboardResponsesList
                        :display-key="key"
                        :loading="loading.includes(key)"
                        :display-responses="displayResponses"
                        :type="type"
                        :free-text="freeText"
                        :is-five-score="isFiveScore"
                        :show-badges="showBadges"
                        :has-selected-badge="hasSelectedBadge"
                        :results-depleted="resultsDepleted"
                    />
                </Intersectional>
                <Scrollable
                    v-else
                    :on-scroll-end="handleScrollEnd"
                    :style="`max-height: calc(100vh - ${calculateScrollHeight()}px); min-height: 600px;`"
                >
                    <DashboardResponsesList
                        :display-key="key"
                        :loading="loading.includes(key)"
                        :display-responses="displayResponses"
                        :type="type"
                        :free-text="freeText"
                        :is-five-score="isFiveScore"
                        :show-badges="showBadges"
                        :has-selected-badge="hasSelectedBadge"
                        :results-depleted="resultsDepleted"
                        :permissions="permissions"
                    />
                </Scrollable>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from 'vue-facing-decorator'
import { Getter, Action, namespace } from 'vuex-facing-decorator'
import { useRoute } from 'vue-router'
import { IFilter, IFilterRule, IResponse, TimeOption } from '@/entities'
import { getResponses } from '@/api/responses'
import Loading from '@/components/Loading.vue'
import Scrollable from '@/components/Scrollable.vue'
import Intersectional from '@/components/Intersectional.vue'
import getData from '@/pages/dashboard/data'
import { dedupBy } from '@/utils/array'
import { formatNumber } from '@/utils/number'
import { ConvUIQuestions } from '@/pages/dashboard/entities/dash'
import { ScorecardEntity } from '@/entities/scorecard'
import ScorecardBadgeHeading from '@/pages/dashboard/components/Scorecard/ScorecardBadgeHeading.vue'
import { unescapeHTML } from '@/utils/string'

import ClearFilterImage from '@/assets/img/Icons/linkicons/clearfilter.svg'
import DashboardResponsesList from '@/pages/dashboard/components/DashboardResponsesList.vue'
import { IScorecardSetting } from '@/pages/appstore/components/Scorecard/scorecard-settings-entity'
import { getScorecardFieldNameById } from '@/domain/Scorecard'

const PAGE_SIZE = 20
const ScorecardSettingsModule = namespace('scorecardSettings')
const AuthModule = namespace('auth')

/*
        This shows data for the currently active filter.
        So it should be re-fetched when the filter changes.
    */
@Component({
    components: {
        ScorecardBadgeHeading,
        Loading,
        Scrollable,
        Intersectional,
        DashboardResponsesList,
    },
})
export default class DashboardResponses extends Vue {
    @Prop({ type: Boolean, default: true }) isSideBar!: boolean
    @Prop({ default: () => [] }) externalFilters!: IFilterRule[]
    @Prop({ type: Boolean, default: false }) showBadges?: boolean
    @Prop({ type: Object, required: false }) scorecard?: ScorecardEntity
    @Prop({ type: Boolean, default: false }) forScorecard?: boolean
    // when used on scorecard page, we want to apply scorecard locked filters
    // on dashboard page, we don't want to apply that
    @Prop({ type: Boolean, default: false })
    applyScorecardLockedFilters?: boolean
    @Prop({ type: Boolean, default: false })
    applyScorecardTemplateFilter?: boolean
    // custom time range if required
    @Prop({ type: String, required: false }) readonly timeUnit?: TimeOption
    @Prop({ type: String, default: '' }) readonly timeValue?: string
    @Prop({ type: String, default: '' }) readonly mobileQuestionType?: string
    @Prop({ type: Array, default: () => [] }) public permissions!: string[]

    public loading: string[] = []
    public freeText = '' // if show free text reply instead of comment and which column to show
    public title = 'NPS' // default title
    public clearFilterLink = '/overview' // link to when click clear filter
    // what kind of response are we getting
    // [theme, leaderboard, nps, csat, multi, freetext]
    public type = 'nps'

    private route = useRoute()

    @Getter
    $companyVars
    @Getter
    filterActive
    @Getter
    filterQuestionType
    @Getter
    filterLastPatch
    @Getter
    themeSettings
    @Getter
    responsesMap
    @Getter
    convQuestions!: ConvUIQuestions
    @Getter
    tsMobileView
    @ScorecardSettingsModule.Getter
    readonly scorecardList!: IScorecardSetting[]
    @Getter
    getPerformanceOverTimeConfig

    @Action
    setResponses
    @Action
    getFilters
    @Action
    updateConvQuestions
    @ScorecardSettingsModule.Action
    loadScorecardList

    @AuthModule.Getter getSelectedScorecard

    public resultsDepleted = false
    public scorecardListLoaded = false

    // format to 123,456.7
    public formatNumber = formatNumber

    public unescapeHTML = unescapeHTML

    private scrollableTemplate = {
        component: {
            object: Scrollable,
            params: {
                onScrollEnd: this.handleScrollEnd,
                style: this.scrollableStyle,
            },
        },
    }

    private intersectionalTemplate = {
        component: {
            object: Intersectional,
            params: {
                onScrollEnd: this.handleScrollEnd,
            },
        },
    }

    public get currentPath() {
        return this.route.path
    }

    public get displayResponses() {
        const responses = this.responsesMap[this.key] as Array<IResponse>
        return responses && responses.length
            ? dedupBy(responses, ({ id }) => id?.toString() ?? 0)
            : []
    }

    public async mounted() {
        if (this.scorecardList.length < 1) {
            await this.loadScorecardList()
            this.scorecardListLoaded = true
        }

        if (
            !this.responsesMap[this.key] &&
            this.route?.name !== 'performanceOverTimeGraphPageV1'
        ) {
            if (this?.route?.meta?.hideFilter) {
                await this.getFilters()
            }

            await this.updateDashboardResponses()
        }

        if (Object.keys(this.convQuestions).length < 1) {
            await this.updateConvQuestions()
        }
    }

    public get scrollableStyle() {
        return this.tsMobileView
            ? `max-height: 100%;`
            : `max-height: calc(100vh - ${this.calculateScrollHeight()}px); min-height: 400px;`
    }

    public get unescapedTitle() {
        return this.unescapeHTML(this.title)
    }

    public get hasArchived() {
        for (const key in this.convQuestions) {
            if (!this.convQuestions.hasOwnProperty(key)) {
                continue
            }
            if (Number(this.convQuestions[key].settings.is_archived)) {
                return true
            }
        }
        return false
    }

    public get hasSelectedBadge() {
        if (!this.scorecard) {
            return false
        }

        return !!this.scorecard.selectedBadge
    }

    public get hasSelectedPositiveBadge() {
        if (!this.scorecard) {
            return false
        }

        if (!this.scorecard.selectedBadge) {
            return false
        }

        return this.scorecard.selectedBadge.type === 'positive'
    }

    public get scrollDisplayObject() {
        return this.tsMobileView
            ? this.intersectionalTemplate.component.object
            : this.scrollableTemplate.component.object
    }

    public get scrollDisplayParams() {
        return this.tsMobileView
            ? this.intersectionalTemplate.component.params
            : this.scrollableTemplate.component.params
    }

    public getClearFilterImage() {
        return ClearFilterImage
    }

    public clearFilter() {
        if (this.scorecard && this.hasSelectedBadge) {
            this.scorecard.selectedBadge = null
        }
    }

    public get responseLabel() {
        return (this.title || 'NPS').length > 20 ? 'resp' : 'responses'
    }

    /** The key used to fetch responses from responses object
     * so that we can cache some responses when switching tabs
     *
     * We need the route param here too in case we are on the case management page
     * which does not use the filter rules but the route param instead.
     */
    public get key() {
        // for scorecard locked admin, responses on dashboard and scorecard page are different
        return JSON.stringify({
            ...this.effectiveFilter,
            scorecard: this.applyScorecardLockedFilters,
            scoreboard: this.applyScorecardTemplateFilter,
            selectedScorecard: this.getSelectedScorecard,
        })
    }

    /**
     * Gets extra filter rule depending on the current route
     */
    public get extraFilter(): IFilterRule[] {
        this.freeText = '' // set to non-free text by default
        this.title = 'NPS'
        this.type = 'nps'

        const { path, query } = this.route
        const filterType = query.filter_type as string

        // check if filtering by nps type first in query
        if (filterType) {
            const titleMap = {
                promoters: 'satisfied',
                passives: 'neutral',
                detractors: 'not satisfied',
            }
            this.title = this.isFiveScore ? titleMap[filterType] : filterType
            this.clearFilterLink = '/overview'
            if (!this.isFiveScore) {
                return [
                    { column: 'rating', operator: 'in', value: [filterType] },
                ]
            } else {
                const map = {
                    promoters: ['4', '5'],
                    passives: ['3'],
                    detractors: ['1', '2'],
                }
                return [
                    {
                        column: 'answer',
                        operator: 'in',
                        value: map[filterType],
                    },
                ]
            }
        }

        // prepare for clear filter link
        this.clearFilterLink = this.route.path.split('/').slice(0, -1).join('/')
        if (path.includes('/themes')) {
            const themeId = parseInt(String(this.route.params.themeId), 10)
            const theme =
                (this.themeSettings &&
                    this.themeSettings.length &&
                    this.themeSettings.find(
                        (a) => Number(a.id) === Number(themeId)
                    )) ||
                false
            this.type = 'theme'
            if (theme) {
                this.title = theme.name
                // treat theme as a multi value column and use question param table
                return [
                    { column: 'theme', operator: 'in', value: [theme.name] },
                ]
            }
        }

        if (path.includes('/leaderboards/')) {
            let { name, value } = this.route.params
            name = name?.toString() ?? null
            value = value?.toString() ?? null

            this.type = 'leaderboard'
            if (name && value) {
                // wait until scorecardList is loaded first before showing it
                if (!this.scorecardListLoaded) {
                    this.title = ''
                } else if (
                    !isNaN(value as any) &&
                    this.scorecardList.length > 0
                ) {
                    this.title = getScorecardFieldNameById(
                        this.scorecardList,
                        name,
                        value
                    )
                } else {
                    this.title = value
                }
                return [{ column: name, operator: 'in', value: [value] }]
            }
        }

        if (path.includes('/questions/')) {
            let { questionKey, questionValue } = this.route.params
            questionKey = questionKey.toString()
            questionValue = questionValue.toString()

            if (questionKey === 'all' || questionKey === 'archived') {
                this.title = 'NPS'
                this.type = 'all'
                return []
            }
            // set is free text
            const question = this.convUiQuestions[questionKey]
            const options = question && question.options
            if (questionKey.match(/_nps_c$/)) {
                this.type = 'nps'
                this.title = questionValue.toString() || ''
            } else if (
                !questionKey.match(/_csat(_c)?$/) &&
                Array.isArray(options)
            ) {
                this.freeText = questionKey
                this.type = 'freetext'
                this.title = (
                    questionValue
                        ? this.getSentimentWord(questionValue)
                        : 'Text'
                ) as string
            } else if (questionKey.match(/_csat(_c)?$/)) {
                this.type = 'csat'
                this.title = questionValue
                    ? (options && options[questionValue]) || questionValue
                    : 'CSat'
            } else if (questionKey.match(/_5star(_c)?$/)) {
                this.type = '5star'
                this.title = questionValue
                    ? (options && options[questionValue]) || questionValue
                    : '5 STAR'
            } else {
                this.type = 'multi'
                let { questionKey } = this.route.params
                questionKey = questionKey.toString()

                // try convert numeric scorecard value to topic name
                if (
                    this.scorecardList.length > 0 &&
                    this.scorecardList.find(
                        (scorecard) => scorecard.field === questionKey
                    ) &&
                    questionValue?.match(/^\d+$/)
                ) {
                    this.title = getScorecardFieldNameById(
                        this.scorecardList,
                        questionKey,
                        questionValue
                    )
                }
                // if failed, follow normal process
                if (this.title === questionValue) {
                    this.title = questionValue
                        ? (options && options[questionValue]) || questionValue
                        : 'Multi'
                }
            }

            if (questionKey) {
                if (questionValue) {
                    if (
                        questionKey !== 'initial_nps_c' &&
                        /_nps_c$/.test(questionKey)
                    ) {
                        const values = {
                            promoters: [9, 10],
                            passives: [7, 8],
                            detractors: [0, 1, 2, 3, 4, 5, 6],
                        }
                        return [
                            {
                                column: questionKey,
                                operator: 'in',
                                value: values[questionValue],
                            },
                        ]
                    }
                    let column
                    if (questionKey === 'initial_nps_c') {
                        column = 'rating'
                    } else if (
                        questionKey === 'initial_csat_c' ||
                        questionKey === 'initial_5star_c'
                    ) {
                        column = 'answer'
                    } else {
                        column = questionKey
                    }
                    return [
                        {
                            column,
                            operator: 'in',
                            value: [questionValue],
                        },
                    ]
                } else {
                    // we don't want to show null values for multi questions.
                    return [
                        {
                            column: questionKey,
                            operator: 'isnotnull',
                            value: ['whatever'],
                        },
                        {
                            column: questionKey,
                            operator: 'isnotempty',
                            value: ['whatever'],
                        },
                    ]
                }
            }

            this.title =
                this.title || (questionKey ? this.fname(questionKey) : '')
        }

        // implement filter rule derivation for other routes here
        if (this.scorecard && this.showBadges && this.hasSelectedBadge) {
            this.title =
                (this.scorecard.selectedBadge?.label ||
                    this.scorecard.selectedBadge?.value) ??
                ''
            this.clearFilterLink = '/scorecard'
        }

        return []
    }

    public get effectiveFilter(): IFilter | false {
        if (!this.filterActive) {
            return false
        }

        let dateFilter = {}
        let scorecardFilters: IFilterRule[] = []
        if (this.forScorecard) {
            dateFilter = {
                time_unit: 'any', // ignore time
                time_value: '1',
            }
            // don't show no comment filter when selecting specific badge
            if (!this.externalFilters || !this.externalFilters.length) {
                scorecardFilters = [
                    {
                        operator: 'isnotempty',
                        column: 'comment',
                        value: ['thisisauniquenotemptyvalue123987'],
                    },
                ]
            }
            if (
                this.scorecard &&
                this.scorecard.scorecardField &&
                !this.$companyVars.has_ai_themes_for_scorecard
            ) {
                scorecardFilters.push({
                    operator: 'isnotempty',
                    column: this.scorecard.scorecardField,
                    value: [''],
                })
            }

            if (
                this.$companyVars.has_ai_themes_for_scorecard &&
                this.scorecardList.length > 0 &&
                (!this.externalFilters || !this.externalFilters.length) // don't add theme filter when selecting specific badge
            ) {
                const scorecard = this.scorecardList.find(
                    (scorecard) =>
                        scorecard.field === this.scorecard?.scorecardField
                )

                if (scorecard) {
                    const topicNames =
                        scorecard.scorecardTopics.map(
                            (topic) => topic.topic_name
                        ) || []

                    if (topicNames.length > 0) {
                        scorecardFilters.push({
                            operator: 'in',
                            column: 'theme',
                            value: topicNames,
                        })
                    }
                }
            }
        }

        let filteredFilter = this.filterActive.filter_rules

        if (this.externalFilters.filter((a) => a.column === 'rating').length) {
            filteredFilter = this.filterActive.filter_rules.filter(
                (a) => a.column !== 'rating'
            )
        }

        const effective = {
            ...this.filterActive,
            ...dateFilter,
            filter_rules: [
                ...filteredFilter,
                ...this.externalFilters,
                ...scorecardFilters,
            ].concat(this.extraFilter),
        } as IFilter

        // allow explicit date range
        if (this.timeUnit && this.timeValue) {
            effective.time_unit = this.timeUnit as TimeOption
            effective.time_value = this.timeValue
        }

        if (this.mobileQuestionType) {
            effective.question_type = this.mobileQuestionType
        }

        // if click on promoters etc link, then ignore 'rating in any' filter from nav bar
        if (this.extraFilter.filter((a) => a.column === 'rating').length) {
            return {
                ...effective,
                filter_rules: [
                    ...this.filterActive.filter_rules.filter(
                        (a) => a.column !== 'rating'
                    ),
                ].concat(this.extraFilter),
            }
        }

        return effective
    }

    @Watch('filterActive')
    @Watch('effectiveFilter')
    @Watch('route.path')
    @Watch('getSelectedScorecard')
    public onDataChanged() {
        if (this.route?.name === 'performanceOverTimeGraphPageV1') {
            return
        }
        // TODO: Refactor this into composition API to fix reactivity issue
        // Adding a debounce since the filter reactivity is triggering twice
        // and would result into duplicated API calls
        setTimeout(() => {
            this.updateDashboardResponses()
        }, 250)
    }

    @Watch('getPerformanceOverTimeConfig', { deep: true })
    public performanceOvertimeConfigChanged() {
        if (!this.effectiveFilter) {
            return
        }

        // Get PerformanceOverTimeConfig changed from main view PerformanceOverTime component
        let config = this.getPerformanceOverTimeConfig
        //update question type
        this.effectiveFilter['question_type'] = config.questionType
        // update time_value changed from PerformanceOvertime dropdown date range selection
        this.effectiveFilter['time_value'] = config.timeRange
        this.effectiveFilter['time_unit'] = config.timeUnit
        // Add PerformanceOverTime table value selected to filter_rules
        const filter_source = 'performanceOverTime'
        // remove previous added customValue filter
        this.effectiveFilter['filter_rules'] = this.effectiveFilter[
            'filter_rules'
        ]?.filter((obj) => {
            return obj.source !== filter_source
        })

        const value: string[] = []
        const newFilterRule = {
            column: '',
            operator: 'in',
            value: value,
            source: filter_source,
        }
        if (config.customValue) {
            // Add new customField,customValue to the filter for retrieving responses
            newFilterRule.column = config.customField
            newFilterRule.value = [config.customValue]
            this.effectiveFilter['filter_rules']?.push(newFilterRule)
        }

        // reset responsesMap to allow reloading new Responses base on PerformanceOverTimeConfig change
        this.responsesMap[this.key] = undefined
        this.updateDashboardResponses()
    }

    public async updateDashboardResponses() {
        if (this.loading.includes(this.key) || !this.effectiveFilter) {
            return
        }
        // Key will be changed when filter is changed or route is changed
        const responseKey = JSON.stringify(JSON.parse(this.key))
        if (!this.responsesMap[this.key]) {
            const { path } = this.route
            let { questionKey } = this.route.params
            questionKey = questionKey?.toString() ?? 'null'
            this.loading.push(responseKey)
            this.resultsDepleted = false
            const params = this.checkFilterValues(this.effectiveFilter)
            const includeSelectedScorecard =
                path.includes('/coaching') ||
                (this.$companyVars.has_ai_themes_for_scorecard &&
                    path.includes('/teamscoreboard'))
            const { data } = await getResponses(params, {
                pagesize: PAGE_SIZE,
                offset: 0,
                freetext: this.freeText,
                customFields: questionKey ? [questionKey] : [],
                withtotal: true,
                applyScorecardLockedFilters: this.applyScorecardLockedFilters
                    ? 1
                    : 0,
                applyScorecardTemplateFilter: this.applyScorecardTemplateFilter
                    ? 1
                    : 0,
                selectedScorecard: includeSelectedScorecard
                    ? this.getSelectedScorecard
                    : null,
            }) // reset to start
            this.setResponses({ key: responseKey, data })
            this.loading = this.loading.filter((item) => item !== responseKey)
        }
    }

    public checkFilterValues(filters: IFilter) {
        const { filter_rules } = filters

        const isScoreCard = this.scorecard?.scorecardField
        // If there is a scorecard, redo the filtering
        const hasScorecard = filter_rules?.filter(
            (item) => item.column === isScoreCard
        )
        const isPersonalPage = this.route.path === '/personalview'
        // Only filter this way if:
        // - In `personalview` page
        // - Part of the filter is scorecard and;
        // - Scorecard field is not null
        if (isScoreCard && hasScorecard && isPersonalPage) {
            const picked = filter_rules?.filter(
                (item) =>
                    item.column === 'rating' ||
                    item.column === isScoreCard ||
                    item.column === 'theme'
            )

            let reconstruct = filters
            reconstruct.filter_rules = picked

            return reconstruct
        }

        // otherwise, return the original filters
        return filters
    }
    public async loadMoreResponses() {
        const { key, freeText, effectiveFilter } = this
        if (this.loading.includes(key) || !effectiveFilter) {
            return
        }

        this.loading.push(key)
        let { questionKey } = this.route.params
        const { data } = await getResponses(effectiveFilter, {
            pagesize: PAGE_SIZE,
            offset: this.responsesMap[key]?.length ?? 0,
            freetext: this.freeText,
            customFields: questionKey ? [questionKey.toString()] : [],
            withtotal: false,
            applyScorecardLockedFilters: this.applyScorecardLockedFilters
                ? 1
                : 0,
            applyScorecardTemplateFilter: this.applyScorecardTemplateFilter
                ? 1
                : 0,
        })
        this.loading = this.loading.filter((item) => item !== key)
        if (data.length > 0) {
            if (!this.responsesMap[key]) {
                this.responsesMap[key] = []
            }
            this.responsesMap[key] = this.responsesMap[key].concat(data)
        } else {
            this.resultsDepleted = true
        }
    }

    public get convUiQuestions() {
        return (getData() || { convUiQuestions: {} }).convUiQuestions
    }

    public getSentimentWord(category) {
        if (Number(category) === 1) {
            return 'Positive'
        } else if (Number(category) === 0) {
            return 'Neutral'
        } else if (Number(category) === -1) {
            return 'Negative'
        }
    }

    public fname(str) {
        return str
            .replace(/_c$/, '')
            .split('_')
            .map((name) => name.charAt(0).toUpperCase() + name.slice(1))
            .join(' ')
    }

    public get isFiveScore() {
        // if on the scorecard we should use the questionType from /ajax/scorecard which is the scorecard locked filter
        if (this.applyScorecardLockedFilters && this.scorecard) {
            return this.scorecard.isFiveScoreQuestionType
        } else {
            return this.filterQuestionType
                ? this.filterQuestionType !== 'nps'
                : false
        }
    }

    public handleScrollEnd() {
        if (!this.resultsDepleted) {
            this.loadMoreResponses()
        }
    }

    // max height of scrollable area: responses
    // should be view port height - nav - title, etc
    public calculateScrollHeight() {
        if (this.$refs.responses) {
            return (
                (this.$refs.responses as any).getBoundingClientRect().y +
                window.scrollY +
                10
            )
        } else {
            return 10
        }
    }
}
</script>

<style scoped lang="less">
@import '../../../styles/palette';
@import '../../../styles/layout';

.responses-container {
    color: @fontColor;

    .title {
        margin-bottom: 5px;
        display: flex;

        .text {
            flex-grow: 1;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 1.04px;
            text-transform: uppercase;
            color: #9399a2;

            .filter {
                color: @blue;
            }

            .router-link-container {
                padding-left: 5px;
                display: inline-block;
                img {
                    cursor: pointer;
                }
            }

            .clear-filter {
                margin-left: 3px;
                position: relative;
                top: -1px;
                cursor: pointer;
            }
        }

        .showing {
            color: #96a0aa;
            font-size: 12px;
        }
    }

    .body:extend(.box) {
        background: white;
        margin-bottom: 0;

        .header {
            padding: 20px;
            border-bottom: 1px solid rgba(46, 91, 255, 0.08);
            color: #96a0aa;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 1.21px;
            text-transform: uppercase;
        }

        .responses {
            position: relative;
        }
    }
}
</style>
