import * as amplitude from '@amplitude/analytics-browser'
import mobileStore from '@/mobile/src/store'
const { amplitudeApiKey } = require('@/mobile/src/utils/amplitude.ts')
export function initializeAmplitude(): void {
    // Disabling this for now
    // if (
    //     process.env.VUE_APP_APP_ENV !== 'local' ||
    //     process.env.VUE_APP_FORCE_AMPLITUDE === 'true'
    // ) {
    //     try {
    //         amplitude.init(amplitudeApiKey, {
    //             fetchRemoteConfig: true,
    //             autocapture: {
    //                 pageViews: false,
    //                 elementInteractions: true,
    //             },
    //         })
    //         // Set amplitude in window
    //         window.amplitude = amplitude
    //     } catch (error: unknown) {
    //         console.error(
    //             'Failed to initialize Amplitude. Error:',
    //             error instanceof Error ? error.message : String(error)
    //         )
    //     }
    // }
}

export function trackAmplitudeMobile(pageTitle: string, attempts = 0): void {
    // Disabling this for now
    // if (!window.amplitude) return
    // const email = mobileStore.getters['email']
    // const userRole = mobileStore.getters['userRole']
    // const userRoleType = mobileStore.getters['userRoleType']
    // const userName = mobileStore.getters['userName']
    // const company = mobileStore.getters['companyName']
    // if (!email) {
    //     if (attempts < 3) {
    //         console.info(`Waiting for user info... Retry (${attempts + 1}/3)`)
    //         setTimeout(
    //             () => trackAmplitudeMobile(pageTitle, attempts + 1),
    //             1000
    //         )
    //     } else {
    //         console.error(
    //             'Failed to track analytics: User info not available after retries.'
    //         )
    //     }
    //     return
    // }
    // try {
    //     const identify = new window.amplitude.Identify()
    //     identify.set('User Role Type', userRoleType)
    //     identify.set('User Role', userRole)
    //     identify.set('Email', email)
    //     identify.set('Name', userName)
    //     identify.set('Company', company)
    //     identify.set('Platform', 'Mobile')
    //     window.amplitude.identify(identify)
    //     window.amplitude.setGroup('Company', company)
    //     window.amplitude.setUserId(email)
    //     window.amplitude.track('[Amplitude] Page Viewed', {
    //         '[Amplitude] Page Title': pageTitle,
    //     })
    // } catch (error) {
    //     console.error('Error tracking analytics event:', error)
    // }
}
