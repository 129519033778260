<template>
    <RouterView v-slot="{ Component }">
        <PushPopTransitionContainer>
            <KeepAlive>
                <component :is="Component" />
            </KeepAlive>
        </PushPopTransitionContainer>
    </RouterView>
</template>

<script lang="ts">
import { useRoute, useRouter } from 'vue-router'
import { IMessage } from '@/entities/notices'
import { getCompanyPendoAccountProperties } from '@/mobile/src/api/auth'
import PushPopTransitionContainer from '@/mobile/src/components/containers/PushPopTransitionContainer.vue'
import { gatherDeviceInfo, getAppVersion } from '@/mobile/src/utils/capacitor'
import { isLocal } from '@/mobile/src/utils/misc'
import requestPushNotificationPermission from '@/mobile/src/utils/pushnotification'
import { LooseObject } from '@/pages/appstore/entities/app'
import { Component, Vue, Watch } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'
import { PushNotifications } from '@capacitor/push-notifications'
import { Capacitor } from '@capacitor/core'
import { deviceCheck } from '@/mobile/src/utils/device'

@Component({
    components: {
        PushPopTransitionContainer,
    },
})
export default class LoggedIn extends Vue {
    private route = useRoute()
    private router = useRouter()

    @Getter
    readonly accesstoken!: string

    @Getter
    readonly companyProps!: LooseObject

    @Getter
    readonly userId!: string

    @Getter
    readonly userName!: string

    @Getter
    readonly email!: string

    @Getter
    readonly phone!: string

    @Getter
    readonly userRole!: string

    @Getter
    readonly userRoleType!: string

    @Getter
    readonly userMasterRole!: string

    @Getter
    readonly userMasterRoleType!: string

    @Getter public userHasMlp

    @Getter
    readonly pushPermission

    @Getter
    readonly permissions!: Array<string>

    @Getter
    readonly received!: Array<IMessage>

    @Getter
    readonly disablePendo!: boolean

    @Getter
    readonly $companyVars

    @Action
    setTsMobileView

    @Action
    setTabUnreadCount

    @Action
    dispatchStoreLogin

    @Action
    setCanSendNotices

    @Action
    fillOfflineCache

    @Watch('received')
    onReceivedNoticesUpdated(notices: Array<IMessage>) {
        if (!notices) {
            return
        }
        //Watching here ensures that everything updates properly and lets us get around the mobile/web divide.
        const unreadCount = notices.filter((notice) => !notice.seen).length
        this.setTabUnreadCount({ name: 'notices', count: unreadCount })
    }

    @Watch('pushPermission')
    async checkPushDevice(
        newPushPermission = undefined,
        oldPushPermission = undefined
    ) {
        if (
            [undefined, 'unknown'].includes(newPushPermission) ||
            oldPushPermission === 'unknown'
        ) {
            return
        }

        await gatherDeviceInfo() // update device info
        // make sure we register user's push token if permission granted
        // most of the time, we might already have this in our db
        // but on app updates or device change, we might loose it
        // this is just to make sure. NOTE: it won't show the pop modal as it's already granted
        if (Capacitor.isPluginAvailable('PushNotifications')) {
            const result = await PushNotifications.checkPermissions()
            if (result.receive === 'granted') {
                await requestPushNotificationPermission()
            }
        }
    }

    // if not logged in, can't access this page
    @Watch('accesstoken') // recheck auth when ever token is changed.
    async checkLogin() {
        if (!this.accesstoken) {
            return this.router.replace({ name: 'findlogin' })
        }
        await this.setPermissions()
        if (!this.accesstoken && this.route.name !== 'findlogin') {
            return this.router.replace({ name: 'findlogin' })
        }
    }

    beforeMount() {
        this.checkLogin()
        this.setTsMobileView(true) // set to make shared mobileFilterBar to get tsMobileView value
    }

    async mounted() {
        await this.checkPushDevice()
        const appVersion = await getAppVersion()
        const accountPropertiesResponse =
            await getCompanyPendoAccountProperties()
        // Initalize Pendo only when 1. not local  2. Pendo hasn't been disabled
        // if (!isLocal && !this.disablePendo) {
        //     window.pendo.initialize({
        //         visitor: {
        //             id: this.email,
        //             asknicely_id: `${this.companyProps.domain}_${this.userId}`,
        //             domain: this.companyProps.domain,
        //             mobile: this.phone,
        //             email: this.email,
        //             full_name: this.userName,
        //             role: this.userMasterRole ?? this.userRole, // Fallback to userRole
        //             business_role: this.userMasterRoleType ?? this.userRoleType, // Fallback to userRoleType
        //             device: 'frontline',
        //             app_version: appVersion,
        //             has_mlp: this.userHasMlp
        //                 ? 'AskNicely 3.0'
        //                 : 'Legacy Mobile',
        //         },
        //         account: accountPropertiesResponse.data,
        //     })
        // }
        await this.fillOfflineCache()
    }

    async setPermissions() {
        await this.dispatchStoreLogin()
        this.setCanSendNotices(this.permissions.includes('ROLE_SEND_NOTICES'))
        await deviceCheck()
    }
}
</script>

<style scoped></style>
